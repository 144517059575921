<template>
  <div class="repreneurRegistrationStep2">
    <h3 class="gavc-h3">L'entreprise concernée par la cession</h3>

    <cedantInformation
      v-on:invalidInformationChanged="isCedantInvalidValue = $event"
      v-on:isExistingCedantCompanyChanged="isExistingCedantCompany = $event"
      v-on:isExistingCedantUsernameChanged="isExistingCedantUsername = $event"
    />

    <div class='row mb-4'>
      <div class="col-12 text-right">
        <button
          class="mt-2 gavc-btn gavc-btn--bone"
          @click="previousStep">
            Précédent
        </button>
        <button
          class="mt-2 ml-2 gavc-btn"
          @click="nextStep"
          :disabled="(isCedantInvalidValue || isExistingCedantUsername) && !isExistingCedantCompany ">
            Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>

const cedantInformation = () => import('@/components/registration/repreneur/cedantRegistration/cedantInformation')

export default {
  name: 'repreneurRegistrationEntrepriseCible',
  components: {
    cedantInformation
  },
  data: function () {
    return {
      isCedantInvalidValue: true,
      isExistingCedantCompany: false,
      isExistingCedantUsername: false
    }
  },
  mounted () {
    this.scrollToTop()
  },

  methods: {
    nextStep () {
      this.$emit('nextStep')
    },
    previousStep () {
      this.$emit('previousStep')
    }
  }
}
</script>
